import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div>
    <div className="footer">
        <h2 className="footer2">Specialized, Local, Intimate, Culinary, Experience</h2>
        <h3 className="footer3">Made by Nick Reese 2021
        </h3>
    </div>
        </div>
    )
}

export default Footer
